// extracted by mini-css-extract-plugin
export var beta = "index-module--beta--UkiI9";
export var body = "index-module--body--A6eno";
export var codeInput = "index-module--codeInput--Q+17d";
export var codeLabel = "index-module--codeLabel--iowEG";
export var content = "index-module--content--Jl00Y";
export var demoCode = "index-module--demoCode--xCK2G";
export var divider = "index-module--divider--xCANZ";
export var firstName = "index-module--firstName--Tp6pj";
export var header = "index-module--header--+JMd8";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--FGMIh";
export var names = "index-module--names--DYjDK";
export var whatsNext = "index-module--whatsNext--BVvrA";