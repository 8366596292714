import React, { useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { PageLayout, Typography } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import Input from "@components/Input/Input";
import { useOnScroll, useWindowSize } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";
import { APP_BACKEND_URL } from "@components/EnvironmentHelper/EnvironmentHelper";
import Button, {
	Submitbutton,
	SubmitStatus,
} from "@components/Button_new/Button";

const BETA_CODE = "genemodai";
const URL = `${APP_BACKEND_URL}/user/beta-program-email/`;

export default function Beta({ location }: any) {
	const { isMobile, isTablet } = useWindowSize();
	const isDesktop = !isMobile && !isTablet;

	const [submitStatus, setSubmitStatus] =
		useState<SubmitStatus>("NOT_SUBMITTED");

	const [email, setEmail] = useState(location?.state?.email || "");
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [isFirstNameValid, setIsFirstNameValid] = useState(false);
	const [lastname, setLastname] = useState("");
	const [isLastNameValid, setIsLastNameValid] = useState(false);
	const isValid = isEmailValid && isFirstNameValid && isLastNameValid;

	const whatsNextRef = useRef<HTMLDivElement>(null);

	const onError = () => setSubmitStatus("ERROR");

	const hitEmailUrl = () => {
		setSubmitStatus("SUBMITTING");
		fetch(URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				firstname,
				lastname,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					onError();
					return;
				}
				setSubmitStatus("SUCCESS");
				// scroll to make sure whats next section is in view
				setTimeout(() => {
					if (whatsNextRef.current) {
						whatsNextRef.current.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}
				}, 100);
			})
			.catch(onError);
	};

	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "demo" }}>
			<CodeOverlay />
			<BackgroundFadeContainer type="solidBlue" withHeaderPadding>
				<div className={styles.beta}>
					<div className={styles.content}>
						<div className={styles.header}>
							<Typography variant="HERO" color="ui-01">
								Welcome to Genemod{"\n"}
								<span
									style={{
										color: "var(--brand)",
									}}
								>
									Private Beta
								</span>
							</Typography>
						</div>
						<div className={styles.body}>
							<div className={styles.names}>
								<Input
									label={"First name"}
									className={styles.firstName}
									value={firstname}
									onChange={setFirstname}
									autoComplete="given-name"
									fullWidth
									required
									onValidityChange={setIsFirstNameValid}
								/>
								<Input
									label={"Last name"}
									value={lastname}
									onChange={setLastname}
									autoComplete="family-name"
									fullWidth
									required
									onValidityChange={setIsLastNameValid}
								/>
							</div>
							<Input
								label={"Email"}
								value={email}
								onChange={setEmail}
								autoComplete="email"
								fullWidth
								emailValidator
								onValidityChange={setIsEmailValid}
							/>
							<Submitbutton
								submitStatus={submitStatus}
								theme={"light"}
								disabled={!isValid}
								successMessage={
									<Typography color="white">
										Thanks for joining!
									</Typography>
								}
								onClick={hitEmailUrl}
							>
								Join Beta
							</Submitbutton>
							<Typography variant="BODY" color="ui-01">
								Streamline and elevate your research journey
								with our AI-powered lab notebook in exclusive
								Private Beta.
							</Typography>
						</div>
					</div>
					{submitStatus === "SUCCESS" && (
						<WhatsNextSection divRef={whatsNextRef} />
					)}
				</div>
			</BackgroundFadeContainer>
		</PageLayout>
	);
}

type WhatsNextSectionProps = {
	divRef: React.RefObject<HTMLDivElement>;
};

const WhatsNextSection = ({ divRef }: WhatsNextSectionProps) => (
	<div className={styles.whatsNext} ref={divRef}>
		<div className={styles.divider} />
		<div className={styles.header}>
			<Typography variant="HEADER3" color="white">
				What's next:
			</Typography>
		</div>
		<div className={styles.body}>
			<ol type="1">
				<li>
					<Typography>
						<b>Check your email</b> for the exclusive sign up link,
						and create an account.
					</Typography>
				</li>
				<li>
					<Typography>
						<b>Customize your virtual lab.</b> Easily add, find, and
						edit data.
					</Typography>
				</li>
				<li>
					<Typography>
						<b>Share with your team.</b> Get everyone on the same
						page.
					</Typography>
				</li>
				<li>
					<Typography>
						<b>Scale with us.</b> Set your team up for long term
						success by centralizing your data.
					</Typography>
				</li>
			</ol>
		</div>
	</div>
);

const CodeOverlay = () => {
	const [codeEntered, setCodeEntered] = useState(false);
	const codeEnteredRef = useRef(false);
	codeEnteredRef.current = codeEntered;
	const [code, setCode] = React.useState(BETA_CODE);
	const codeIsValid = code === BETA_CODE;

	useOnScroll(() => {
		if (codeEnteredRef.current) {
			return;
		}
		window.scrollTo(0, 0);
	});

	if (codeEntered) {
		return <></>;
	}

	return (
		<div className={styles.demoCode}>
			<StaticImage
				loading="eager"
				src="../../../assets/images/logos/with-black-title.png"
				alt="Genemod logo"
				width={200}
				style={{
					marginTop: 30,
				}}
			/>
			<div className={styles.body}>
				<Typography className={styles.codeLabel} variant="SUBTITLE">
					Access code:
				</Typography>
				<Input
					label={"Code"}
					value={code}
					onChange={setCode}
					className={styles.codeInput}
					fullWidth
				/>
				<Button
					style={{
						width: "100%",
						opacity: codeIsValid ? 1 : 0.5,
					}}
					disabled={!codeIsValid}
					link="BETA"
					onClick={() => {
						setCodeEntered(true);
					}}
				>
					Enter
				</Button>
			</div>
		</div>
	);
};
